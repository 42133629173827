<style scoped>
.page {
    display: block;
}

.page.page-center {
    background-color: #1e293b;
}

.card-login {
    max-width: 600px;
}

.form-control {
    color: white !important;
}

input:-webkit-autofill {
    -webkit-text-fill-color: #999;
}

input:-webkit-autofill:focus {
    -webkit-text-fill-color: #999;
}

.input-group-text {
    height: 56px;
    color: #ffffff;
    background-color: #071838;
    border: none;
}
</style>
<template>
    <div class="page page-center">
        <div class="container container-tight py-4 card-login">
            <div style="height: 200px;"></div>
            <div class="card card-md bg-white">
                <div class="card-body">
                    <h2 class="h2 text-center mb-4">Log in to your account</h2>
                    <form autocomplete="off" novalidate="" @submit="doSubmit">
                        <div class="mb-3">
                            <label class="form-label">Email address or username</label>
                            <input type="email" v-model="email" class="form-control" placeholder="username" autocomplete="off" />
                        </div>
                        <div class="mb-2">
                            <label class="form-label">
                                Password
                            </label>
                            <div class="input-group input-group-flat">
                                <input type="password" v-model="password" class="form-control" placeholder="Your password" autocomplete="off" />
                            </div>
                        </div>
                        <div class="mb-2">
                            <label class="form-check">
                                <input type="checkbox" class="form-check-input" />
                                <span class="form-check-label">Remember me on this device</span>
                            </label>
                        </div>
                        <div class="form-footer">
                            <button class="btn btn-primary w-100" type="submit">
                                Sign in
                            </button>
                        </div>
                    </form>
                </div>
                <!--
        <div class="hr-text">or</div>
        <div class="card-body">
          <div class="row">
            <div class="col">
              <a @click="metamaskLogin" class="btn w-100">
                Login with Metamask
              </a>
            </div>
            <div class="col">
              <a href="#" class="btn w-100"> Login with Twitter </a>
            </div>
          </div>
        </div>
        -->
            </div>
            <div class="text-center text-white mt-3">
                Don't have an account?
                <router-link to="/registry" style="color: #4f9fff" tabindex="-1">Sign up</router-link>
            </div>
            <div class="text-center text-white mt-3">
                Forgot password ?
                <router-link to="/forgot_password_send_email" style="color: #4f9fff" tabindex="2">Click here</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
export default {
    components: {
    },
    name: "login-app",
    data() {
        return {
            email: "",
            password: "",
        };
    },
    methods: {
        metamaskLogin() {
            this.$refs.metamask.init();
        },
        doSubmit: function (e) {
            e.preventDefault();

            axios
                .post(`/api/account/login`, {
                    email: this.email,
                    password: this.password,
                })
                .then((r) => {
                    let data = r.data;
                    if (data.error) {
                        this.$swal(data.msg);
                    } else {
                        console.log(data, 'dt');
                        this.$store.dispatch("account/assign", {
                            access_token: data.access_token,
                            refresh_token: data.refresh_token,
                            user: data.user,
                        });
                        this.$router.push("/");
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>
