import axios from 'axios';
window.Buffer = Buffer; // needed to use `signSmartContractData` in browser
import Web3 from "web3";

const state = {
    mintingStep: 0,
    error: null,
    isMinting: false
}

const actions = {

    /**
     * Make request for minting nft from collection id
     */
    async request({ state, dispatch }, { collectionID, onReject, callback }) {

        if (state.isMinting) {
            return;
        }

        // Get transaction config
        let paymentTransactionData = undefined;
        try {
            let response = await axios.post('/api/minting/request/', {
                item_id: collectionID
            });
            paymentTransactionData = response.data;
            if (paymentTransactionData.error) {


                dispatch("swal/show", {
                    title: "",
                    msg: paymentTransactionData.msg,
                    type: "error",
                    showConfirmButton: true,
                    allowOutsideClick: true
                }, { root: true });

                /*
                commit("UITopupPaymentError", {
                    msg: {
                        msg: paymentTransactionData.msg,
                        title: ' '
                    }
                })*/
                return;
            } else {
                if (paymentTransactionData.already) {
                    callback(' ');

                    dispatch("swal/show", {
                        title: "Congratulations",
                        msg: 'Your NFT mint request successfully.',
                        type: "success",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });

                    /*
                    commit("UITopupPaymentSuccess", {
                        msg: {
                            msg: "Congratulations   ",
                            title: 'Your NFT mint request successfully.'
                        }
                    })*/
                    return;
                }
            }
        } catch (error) {

            dispatch("swal/show", {
                title: "Payment request  error !!",
                msg: error.response.data.msg,
                type: "error",
                showConfirmButton: true,
                allowOutsideClick: true
            }, { root: true });

            /*
            commit("UITopupPaymentError", {
                msg: {
                    msg: error.response.data.msg,
                    title: 'Payment request  error !!'
                }
            })*/
            return
        }

        let targetNetworkId = paymentTransactionData.targetNetworkId;
        let targetWallet = paymentTransactionData.targetWallet;
        let amountCurrency = paymentTransactionData.amountCurrency;
        let transactionID = paymentTransactionData.tid;

        if (amountCurrency == 0) {
            axios.post('/api/minting/request/verify_payment', {
                tid: transactionID,
                txh: ''
            }).then((response) => {
                if (response.data) {
                    if (response.data.error) {
                        /*
                        commit("UITopupPaymentError", {
                            msg: {
                                msg: response.data.msg,
                                title: ' '
                            }
                        })*/
                        dispatch("swal/show", {
                            title: " ",
                            msg: response.data.msg,
                            type: "error",
                            showConfirmButton: true,
                            allowOutsideClick: true
                        }, { root: true });

                    } else {
                        callback(' ');
                        dispatch("swal/show", {
                            title: "Congratulations",
                            msg: 'Your NFT mint request successfully.',
                            type: "success",
                            showConfirmButton: true,
                            allowOutsideClick: true
                        }, { root: true });
                    }
                } else {
                    dispatch("swal/show", {
                        title: " ",
                        msg: 'Unable request to server, please try again',
                        type: "error",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });
                    /*commit("UITopupPaymentError", {
                        msg: {
                            msg: 'Unable request to server, please try again.',
                            title: ' '
                        }
                    })*/
                }
            }).catch((e) => {
                // Can not validate transaction ??? 
                console.log(e);
                /*
                commit("UITopupPaymentError", {
                    msg: {
                        msg: e.response.data.msg,
                        title: ' '
                    }
                })*/
                dispatch("swal/show", {
                    title: " ",
                    msg: e.response.data.msg,
                    type: "error",
                    showConfirmButton: true,
                    allowOutsideClick: true
                }, { root: true });
            })
            return;
        }





        //this.$swal("Please login to access", "Require login", "error");
        if (typeof window.ethereum === "undefined") {

            dispatch("swal/show", {
                title: " ",
                msg: "Please install Metamask and connect your wallet",
                type: "error",
                showConfirmButton: true,
                allowOutsideClick: true
            }, { root: true });

            return;

            /*commit("UITopupPaymentError", {
                msg: {
                    msg: "Please install Metamask and connect your wallet",
                    title: ' '
                }
            })*/
        }

        try {
            await window.ethereum.request({
                method: "wallet_switchEthereumChain",
                params: [{ chainId: targetNetworkId }],
            });
        } catch (err2) {
            console.log(err2);
        }
        state.isMinting = true;


        // Request connect eth network
        try {
            console.log("MINTING", "Open request dialog");

            // Ask to connect
            await window.ethereum.send("eth_requestAccounts");
            /*const instance = new Web3(window.ethereum);
            // Get necessary info on your node
            
            var networkId = await instance.eth.net.getId();
            var coinbase = await instance.eth.getCoinbase();
            var balance = await instance.eth.getBalance(coinbase);
            this.networkId = networkId;
            this.address = coinbase;
            this.balance = balance;
            //console.log("initWeb3 networkId: ", networkId);
            //console.log("coinbase networkId: ", coinbase);
            //console.log("balance networkId: ", balance);
            */


        } catch (error) {
            // User denied account access
            console.error("User denied web3 access", error, targetNetworkId);

            axios.patch('/api/minting/request/status', {
                tid: transactionID,
                status: 4,
                payload: {
                    "msg": "User denied web3 access",
                    error: error.message
                }
            })


            dispatch("swal/show", {
                title: "Minting failed",
                msg: "Error",
                type: "error",
                showConfirmButton: true,
                allowOutsideClick: true
            }, { root: true });

            onReject();

            /*
            return commit("UITopupPaymentError", {
                msg: {
                    msg: "User denied web3 access",
                    title: 'Please install Metamask and connect your wallet '
                }
            })*/
        }

        // amount


        let sendETH = async function sendETH(amount) {
            try {
                // Send request to web3
                let web3 = new Web3(window.ethereum);
                var accounts = await web3.eth.getAccounts();
                var account = accounts[0];


                dispatch("swal/show", {
                    title: "Minting in progress...",
                    msg: "DO NOT close or refresh the page. Please wait until the status changes.",
                    type: "info",
                    showConfirmButton: false,
                    allowOutsideClick: false
                }, { root: true });

                /*

                commit("UITopupPaymentSuccess", {
                    msg: {
                        msg: "DO NOT close or refresh the page. Please wait until the status changes.",
                        title: 'Minting in progress...',
                        hide: true
                    }
                }) */

                axios.patch('/api/minting/request/status', {
                    tid: transactionID,
                    status: 1,
                    receiver: account,
                    payload: {}
                })

                console.log({
                    from: account,
                    to: targetWallet,
                    value: web3.utils.toWei(amount.toString(), "ether"),
                });

                let result = await web3.eth
                    .sendTransaction({
                        from: account,
                        to: targetWallet,
                        value: web3.utils.toWei(amount.toString(), "ether"),
                    });

                state.isMinting = false;
                //dispatch("map/openLoading", { status: false });
                if (result) {
                    console.log('ETH Transfer result', result)
                    return result;
                }
            } catch (error) {
                state.isMinting = false;
                onReject();
                axios.patch('/api/minting/request/status', {
                    tid: transactionID,
                    status: 4,
                    payload: {
                        "msg": "User denied web3 access",
                        error: error.message
                    }
                }).then(() => {

                    dispatch("swal/show", {
                        title: "Transaction error",
                        msg: error.message,
                        type: "error",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });


                }).catch(() => {

                    dispatch("swal/show", {
                        title: "Transaction error",
                        msg: error.message,
                        type: "error",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });

                })
            }
            return
        }
        // After send eth
        let result = await sendETH(amountCurrency)
        if (result) {

            dispatch("swal/show", {
                title: "Minting initiated. Validating your transaction.",
                msg: "DO NOT close or refresh the page. Please wait until the status changes.",
                type: "info",
                showConfirmButton: false,
                allowOutsideClick: false
            }, { root: true });

            /*
            commit("UITopupPaymentSuccess", {
                msg: {
                    msg: "DO NOT close or refresh the page. Please wait until the status changes.",
                    title: 'Minting initiated. Validating your transaction.',
                    hide: true
                }
            })*/

            await axios.patch('/api/minting/request/status', {
                tid: transactionID,
                status: 2,
                payload: result
            })


            // Verify and go to minting step /api/credit/payment/eth_crypto/verify

            axios.post('/api/minting/request/verify_payment', {
                tid: transactionID,
                txh: result.transactionHash
            }).then((response) => {
                if (response.data) {
                    if (response.data.error) {


                        dispatch("swal/show", {
                            title: "",
                            msg: response.data.msg,
                            type: "error",
                            showConfirmButton: true,
                            allowOutsideClick: true
                        }, { root: true });

                        /*commit("UITopupPaymentError", {
                            msg: {
                                msg: response.data.msg,
                                title: ' '
                            }
                        }) */
                    } else {
                        callback(result.transactionHash);

                        dispatch("swal/show", {
                            title: "Congratulations",
                            msg: "Your NFT has been minted successfully.",
                            type: "success",
                            showConfirmButton: true,
                            allowOutsideClick: true
                        }, { root: true });

                        /*
                        commit("UITopupPaymentSuccess", {
                            msg: {
                                msg: "Congratulations   ",
                                title: 'Your NFT has been minted successfully.'
                            }
                        })*/
                    }
                } else {

                    dispatch("swal/show", {
                        title: " ",
                        msg: "Unable request to server, please try again.",
                        type: "error",
                        showConfirmButton: true,
                        allowOutsideClick: true
                    }, { root: true });

                    /*
                    commit("UITopupPaymentError", {
                        msg: {
                            msg: 'Unable request to server, please try again.',
                            title: ' '
                        }
                    })*/
                }
            }).catch((e) => {

                console.log(e);

                dispatch("swal/show", {
                    title: " ",
                    msg: "Cannot validate transaction. Please contact us!",
                    type: "error",
                    showConfirmButton: true,
                    allowOutsideClick: true
                }, { root: true });


            })
        }


    },

};

const mutations = {

};

export const minting = {
    namespaced: true,
    state,
    actions,
    mutations
};