 
<template>
    <div class="container-xl">
        <div style="height: 10rem;"></div>
        <div class="row filter-col">
            <div class="col-6">
                <div class="filter-box mb-4">
                    <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-vercel" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M3 19h18l-9 -15z"></path>
                        </svg> Race</h4>
                    <div id="filters" class="mt-3">
                        <label class="form-check">
                            <input v-model="filter.element.ai" class="form-check-input" type="checkbox">
                            <span class="form-check-label text-class-1">AI</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.element.alien" class="form-check-input" type="checkbox">
                            <span class="form-check-label text-class-2">Alien</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.element.human" class="form-check-input" type="checkbox">
                            <span class="form-check-label text-class-0">Human</span>
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-6">
                <div class="filter-box mb-4">
                    <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-loader-quarter" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M12 6l0 -3"></path>
                            <path d="M6 12l-3 0"></path>
                            <path d="M7.75 7.75l-2.15 -2.15"></path>
                        </svg> Rarity</h4>
                    <div id="filters" class="mt-3">
                        <label class="form-check">
                            <input v-model="filter.rarity.common" class="form-check-input" type="checkbox">
                            <span class="form-check-label">Common</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.rarity.rare" class="form-check-input" type="checkbox">
                            <span class="form-check-label">Rare</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.rarity.epic" class="form-check-input" type="checkbox">
                            <span class="form-check-label">Epic</span>
                        </label>
                        <label class="form-check">
                            <input v-model="filter.rarity.legend" class="form-check-input" type="checkbox">
                            <span class="form-check-label">Legendary</span>
                        </label>
                    </div>
                </div>
            </div>




        </div>
        <div class="row mt-5 filter-col">
            <div class="col-md-12 col-lg-12">
                <div id="rowresult">
                    <div class="page-header mx-2">
                        <div class="page-pretitle">Home > Shop</div>
                        <div class="page-pretitle sort-by-div">
                            <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                                    <path d="M21 21l-6 -6"></path>
                                </svg> {{ total }} items</h4>
                        </div>
                    </div>
                </div>
                <div class="row row-cards list-ships">
                    <div class="ship-item col-6 col-md-4 col-lg-3" v-for="item in items" v-bind:key="item._id">

                        <div :class="'body-ship r-' + item.type">
                            <router-link :to="`/shop/${item._id}`" v-if="item">
                                <div class="header-ship">
                                    <div class="header-title" :class="`text-class-${item.type}`">{{ item.name }}</div>
                                    <div :class="'header-type r-' + item.rarity">
                                        <span class="ship-class mx-2" :class="`ship-class-${item.type}`">
                                        </span>
                                        <template v-if="item.rarity == 0">Common</template>
                                        <template v-if="item.rarity == 1">Rare</template>
                                        <template v-if="item.rarity == 2">Epic</template>
                                        <template v-if="item.rarity == 3">Legendary</template>
                                    </div>
                                </div>
                                <div class="image-ship">
                                    <img :src="getImage(item.image)" />
                                    <span></span>
                                </div>
                                <div class="price-ship mb-1">
                                    <span class="price-indent mx-3 text-org-price">{{ priceRound(item.price) }}</span>
                                    <span class="price-item mx-3"><b>{{ priceRound(item.sell_price) }}</b></span>
                                </div>
                                <div class="footer-ship" @click="addToCart(item)">
                                    <img src="../../static/images/logo-list-item.png" /> Preview
                                </div>
                            </router-link>
                        </div>

                    </div>
                </div>

                <div class="row">
                    <div class="col-12 text-center mt-4 pt-2">
                        <a @click="viewPage(i)" v-for="i in pages" :key="i" class="btn btn-page" :class="{ 'btn-primary': (i == page) }">{{ i }}</a>
                    </div><!--end col-->
                </div>

                <!-- Page body -->
                <div class="page-body mb-5">
                    <div class="container-xl">
                    </div>
                </div>
            </div>
        </div>


        <div v-if="checkoutStatus" id="exampleModal" tabindex="-1" class="modal modal-blur fade show" role="dialog" aria-modal="true" active="true">
            <div class="modal-dialog" id="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            CHECKOUT
                            <p>Purchase.</p>
                        </h5>
                        <button @click="closeCheckoutDialog" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="overflow: hidden">
                        <div class="row">
                            <div class="col-sm-6 title-balance">Price</div>
                            <div class="col-sm-6 value-balance">{{ totalPrice }} USDT</div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-sm-6 text-center">
                                <button @click="onShowFiatPaymentClick" class="btn approve">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-credit-card" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M3 5m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v8a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"></path>
                                        <path d="M3 10l18 0"></path>
                                        <path d="M7 15l.01 0"></path>
                                        <path d="M11 15l2 0"></path>
                                    </svg>

                                    Buy with Credit Card</button>
                            </div>
                            <div class="col-sm-6 text-center">
                                <button @click="onShowCryptoPaymentClick" class="btn approve">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M6 12l6 -9l6 9l-6 9z"></path>
                                        <path d="M6 12l6 -3l6 3l-6 2z"></path>
                                    </svg>

                                    Buy with crypto
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- Modals -->
        <div class="modal modal-blur fade show" id="toup-weirt3" tabindex="-1" role="dialog" aria-modal="true" style="display: block" v-if="isWertCartNoticeDialogShow">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-center">
                            Please Have Your Mobile Phone With You For Secure Identification.
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeNoticeDialog"></button>
                    </div>
                    <div class="modal-body" style="font-size: 11px">
                        <p>
                            By proceeding with the purchase of Nova Frontier X ("Transaction"), you
                            (“User”) understand that your payment and information will be
                            processed by Wert (“SHA2 Solutions”). The User will be redirected to
                            Wert's payment module during the checkout process and the User must
                            read and accept the Terms and Conditions and Privacy Policy prior to
                            finalizing their purchase. Nova Frontier X and The Tipsy
                            Company are not responsible for the handling or processing of any
                            payment or personal information related to the User as part of the
                            Transaction.
                        </p>
                        <p class="text-center">
                            <a class="btn approve" @click="gotoWertPayment">PROCEED</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>



        <div class="modal modal-blur fade show" id="toup-weirt2" tabindex="-1" role="dialog" aria-modal="true" style="display: block" v-if="isWertDialogShow">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">WertIO Payment</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="closeWertDialog"></button>
                    </div>
                    <div class="modal-body" style="padding: 0; min-height: 600px">
                        <div v-if="!wertLoaded" class="loading-wert">
                            Loading ..
                        </div>
                        <div id="widget_landplot"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";
import { mapState } from "vuex";
function objectToConcat(object1) {
    let item = [];
    for (const [key, value] of Object.entries(object1)) {
        if (value) {
            item.push(key);
        }
    }
    return item.join(',');
}


export default {
    name: "HomeMarketpalce",
    components: {
    },
    data() {
        return {
            filter: {
                element: {
                    ai: false,
                    alien: false,
                    earth: false
                },
                rarity: {
                    common: false,
                    rare: false,
                    epic: false,
                    legend: false
                },
                price: {
                    from: 0,
                    to: 0
                }
            },
            value: 1,
            items: [],
            total: '...',
            pages: [],
            page: 1
        };
    },

    methods: {
        viewPage(p) {
            this.page = p;
            this.fetchItems();
            document.getElementById("rowresult").scrollIntoView();
        },
        priceRound(p) {
            return "$" + parseInt(p).toLocaleString();
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },

        fetchItems: async function () {
            let limit = 16;
            try {
                let filterQuery = {
                    element: objectToConcat(this.filter.element),
                    rarity: objectToConcat(this.filter.rarity),
                    price_from: this.filter.price.from,
                    price_to: this.filter.price.to,
                    page: this.page,
                    limit: limit
                }
                let { data } = await axios.get('/api/shop/find', { params: filterQuery });
                console.log(data);
                this.items = data.data;
                this.total = data.total;


                let numberPage = Math.round(data.total / limit);
                let ps = [];
                for (var i = (this.page - 8); i <= this.page + 8; i++) {
                    if (i > 0 && i <= numberPage) {
                        ps.push(i);
                    }
                }
                this.pages = ps;

            } catch (error) {
                console.log(error);
            }
        },
        filterBulder() {
            let filterQuery = {
                element: objectToConcat(this.filter.element),
                rarity: objectToConcat(this.filter.rarity),
                price_from: this.filter.price.from,
                price_to: this.filter.price.to,
            }
            this.$router.push({ query: filterQuery });
            this.fetchItems();
        },







        addToCart(item) {
            this.$store.dispatch("shop/addItems", {
                item: item,
            });
        },
        closeCheckoutDialog: function () {
            this.$store.dispatch("shop/closeCheckoutDialog", {});
        },
        onShowFiatPaymentClick() {
            this.$store.dispatch("shop/showWertNotice", {});
        },

        onShowCryptoPaymentClick() {
            this.$store.dispatch("shop/doStartETHPayment");
        },

        gotoWertPayment() {
            this.$store.dispatch("shop/wertioPayment", {});
        },

        async closeWertDialog() {
            this.$store.dispatch("shop/terminateCartWertIOPayment");
            var result = await this.$swal.fire({
                title: "Do you buy a spaceship with a visa card with Stripe?",
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: "Confirm",
                denyButtonText: `Cancel`
            });
            if (result.isConfirmed) {
                var responseData = await this.$store.dispatch(
                    "cart/creditPaymentStripe",
                    {
                        type: 2,
                        collection: [],
                        items: []
                    }
                );
                console.log(responseData, "responseData Stript");
                if (responseData.error) {
                    this.$swal(responseData.msg, "Sorry", "error");
                } else {
                    document.location.href = responseData.data;
                }
            }
        },

    },

    computed: {
        ...mapState("account", ["status", "creditAmount", "user"]),

        ...mapState("shop", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "landType",
            "landCollection",
            "landPlotBuyResult",
            "addCartException",
            "showDialogPaymentStripe",
            "isWertCartNoticeDialogShow",
            "isWertDialogShow"
        ]),
    },
    watch: {
        landPlotBuyResult: function ({ isError }) {
            this.clearSelectionLand();
            if (!isError) {
                this.fetchLands();
            }
        },

        addCartException: function ({ error, title, msg }) {
            this.$swal(title, msg, error ? "error" : false);
        },

        filter: {
            handler(newValue) {
                console.log(newValue);
                this.page = 1;
                this.filterBulder();
            },
            deep: true
        }
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        this.fetchItems();
    },
};
</script>
