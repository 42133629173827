<style scoped>
.mobile-menu-active {
    display: block !important;
}

.btn-primary {
    background: #088CDF;
    border-radius: 5px;
    border: solid 1px transparent;
}

.btn-primary:hover {
    color: #fff;
    background-color: transparent;
    border: solid 1px #088CDF;
}
</style>
<template>
    <div class="page">
        <header class="header" style="height: 0px">
            <div class="header-main">
                <div class="container">
                    <div class="header-inner position-relative">
                        <div class="row align-items-center">
                            <div class="col-lg-4 col-md-6 col-sm-9 col-6">
                                <div class="d-flex align-items-center">
                                    <div class="logo" style="width: 200px">
                                        <a href="http://novafrontier.io" style="color: white; font-weight: 400; font-size: 18px" to="/"><img width="150" src="../static/images/NovaFrontier.png" class="main-logo" alt="" />
                                            <img width="150" src="../static/images/NovaFrontier.png" class="sticky-logo" alt="" />
                                        </a>
                                    </div>

                                    <!--
                                    <form class="search-form d-none d-sm-block ms-4" action="/">
                                        <div class="form-group">
                                            <button type="submit">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="11.997" height="12" viewBox="0 0 11.997 12" class="svg replaced-svg">
                                                    <path id="search" d="M18.918,17.912l-2.306-2.306a5.3,5.3,0,1,0-1.006,1.006l2.306,2.306a.711.711,0,0,0,1.006-1.006ZM9.68,15.14a3.862,3.862,0,1,1,2.731,1.131A3.866,3.866,0,0,1,9.68,15.14Z" transform="translate(-7.129 -7.125)" fill="#fff"></path>
                                                </svg>
                                            </button>
                                            <input type="text" class="form-control mb-0" placeholder="Search item…" />
                                        </div>
                                    </form>
                                    -->
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-6 col-sm-3 col-6 d-flex align-items-center justify-content-end position-static">
                                <div class="nav-wrapper d-flex align-items-center">
                                    <div id="menu-button" @click="mobileMenuActive = !mobileMenuActive"><span></span></div>
                                    <div class="nav-wrap-inner" :class="{ 'mobile-menu-active': mobileMenuActive }">
                                        <ul class="nav" @click="mobileMenuActive = false">
                                            <li class="has-sub-item">
                                                <a href="https://novafrontier.io/">Home</a>
                                            </li>
                                            <li class="has-sub-item">
                                                <RouterLink to="/">View Spaceships</RouterLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <!-- Navbar -->
        <router-view v-slot="{ Component }">
            <component :is="Component" />
        </router-view>
        <footer class="footer">
            <div class="footer-bottom">
                <div class="container">
                    <div class="row">

                        <div class="col-12">
                            <div class="card card-md" style="background-color: #26282d;">
                                <div class="card-body">
                                    <div class="row align-items-center">
                                        <div class="col-12 text-center">
                                            <h3 class="h1 text-white">Secure Your Early Bird Pricing. Get 50% Off All Spaceships! </h3>
                                            <div class="markdown text-secondary">
                                                Join the launch alert for priority access and secure special Early Bird pricing on Nova Frontier Spaceships.
                                            </div>
                                            <div class="mt-3">
                                                <a href="https://novafrontier.io/#signup" class="btn btn-primary" target="_blank" rel="noopener">Secure Early Bird</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <br />
                    <div class="row">

                        <div class="col-12">
                            <div class="copyright-content text-center text-white">
                                © 2024 Nova Frontier by The Tipsy Company | The Tipsy Company is a registered entity under the Republic of the Marshall Islands (Reg. No. 114167).
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        <a href="#" class="back-to-top"><img src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgYmFzZVByb2ZpbGU9InRpbnkiIGhlaWdodD0iMjRweCIgdmVyc2lvbj0iMS4yIiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNHB4IiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBpZD0iTGF5ZXJfMSI+PHBhdGggZD0iTTEzLDUuNTg2bC00LjcwNyw0LjcwN2MtMC4zOTEsMC4zOTEtMC4zOTEsMS4wMjMsMCwxLjQxNHMxLjAyMywwLjM5MSwxLjQxNCwwTDEyLDkuNDE0VjE3YzAsMC41NTIsMC40NDcsMSwxLDEgICBzMS0wLjQ0OCwxLTFWOS40MTRsMi4yOTMsMi4yOTNDMTYuNDg4LDExLjkwMiwxNi43NDQsMTIsMTcsMTJzMC41MTItMC4wOTgsMC43MDctMC4yOTNjMC4zOTEtMC4zOTEsMC4zOTEtMS4wMjMsMC0xLjQxNEwxMyw1LjU4NnogICAiLz48L2c+PC9zdmc+" /></a>
    </div>
</template>

<script>
//import { RouterLink } from "vue-router";
//import AccountModule from "./components/accountModule.vue";
import { mapState } from "vuex";
//import LandCreditTopup from "./modals/landCreditTopup.vue";
//import LandPlotBuy from "./modals/landPlotBuy.vue";
//import axios from "axios";
//import $ from "jquery";
export default {
    data() {
        return {
            mobileMenuActive: false,
            API_URL: process.env.VUE_APP_BASE_URL,
        };
    },
    components: {},
    computed: {
        ...mapState("account", ["status", "user", "creditAmount"]),
        ...mapState("map", ["openLoading"]),
        ...mapState("swal", ["popup"]),
    },
    watch: {
        popup: function (payload) {
            let { title, type, showConfirmButton, allowOutsideClick, msg } = payload;
            //this.$swal(msg, title, type);
            this.$swal.fire({
                title: title,
                icon: type,
                showDenyButton: false,
                showCancelButton: false,
                showConfirmButton: (showConfirmButton != undefined) ? showConfirmButton : true,
                allowOutsideClick: (allowOutsideClick != undefined) ? allowOutsideClick : true,
                text: msg ? msg : undefined
            });
        }
    },
    methods: {
        cutWalletAddress(str) {
            return str.substr(0, 5) + "..." + str.substr(str.length - 5);
        },
        connectMetamask() {
            this.$swal
                .fire({
                    title: "Connect Wallet",
                    html: 'Connect your wallet to link your account to your wallet address. This enables you to perform blockchain transactions such as managing your NFTs and GIN rewards. <br>',
                    showDenyButton: true,
                    showCancelButton: false,
                    showConfirmButton: true,
                    confirmButton: "connect-metamask",
                    confirmButtonText: '<a><img width="35" src="' + this.API_URL + '/static/swal/metamaskwallet.png" />Connect MetaMask</a>',
                    denyButtonText: `<a><img width="32" style="margin-right: 3px"  src="` + this.API_URL + `/static/swal/safepalwallet.png" />Connect SafePal</a>`,
                    customClass: {
                        confirmButton: 'your-confirm-button-class',
                        cancelButton: 'your-cancel-button-class',
                        denyButton: 'your-deny-button-class',
                    },
                })
                .then((result) => {
                    if (result.isConfirmed || result.isDenied) {
                        this.$store.dispatch("web3_login/metamask");
                    }
                });
        },
        doLogout() {
            this.$swal
                .fire({
                    title: "Are you sure you want to logout?",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Logout",
                    denyButtonText: `Cancel`,
                })
                .then((result) => {
                    result.isConfirmed &&
                        this.$store.dispatch("account/logout") &&
                        this.$store.dispatch("cart/removeItemAll");
                });
        },
    },
    mounted() {
        //$.getScript(this.API_URL + "/static/main.js");
        this.$store.dispatch("account/checkSession");
    },
};
</script>
