<style></style>

<template>
    <div class="container-xl">
        <div style="height: 200px;"></div>
        <div class="row  text-achivement">
            <div class="col-12">
                <div class="card card-md">
                    <div class="card-body">
                        <div class="row align-items-center">
                            <div class="col-10">
                                <h3 class="h1">Peguins to bost power your spaceships</h3>
                                <div class="markdown text-secondary">
                                    Connect your wallet and .

                                    <p>
                                        <button @click="doFetchPeguins" class="btn buynow mt-3 p-3 disable-btn"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-wallet" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M17 8v-3a1 1 0 0 0 -1 -1h-10a2 2 0 0 0 0 4h12a1 1 0 0 1 1 1v3m0 4v3a1 1 0 0 1 -1 1h-12a2 2 0 0 1 -2 -2v-12"></path>
                                                <path d="M20 12v4h-4a2 2 0 0 1 0 -4h4"></path>
                                            </svg> Fetch Peguin On-Chain</button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-center mt-4 pt-2">
                <table class="table table-vcenter table-mobile-md card-table text-white tbl-my-assets table-bordered">
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Address</th>
                    </tr>
                    <tr v-for="(item, index) in items" v-bind:key="item._id">
                        <td>
                            #{{ index + 1 }}
                        </td>
                        <td>
                            {{ item.name }}
                        </td>
                        <td>
                            <img :src="item.image" height="100px" style="height: 100px;" />
                        </td>
                    </tr>
                </table>
                <a @click="viewPage(i)" v-for="i in pages" :key="i" class="btn btn-page" :class="{ 'btn-primary': (i == page) }">{{ i }}</a>
            </div><!--end col-->
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";


export default {
    name: "HomeMarketpalce",
    components: {
    },
    data() {
        return {
            filter: {
                element: {
                    ai: false,
                    alien: false,
                    earth: false
                },
                rarity: {
                    common: false,
                    rare: false,
                    epic: false,
                    legend: false
                },
                price: {
                    from: 0,
                    to: 0
                }
            },
            value: 1,
            items: [],
            total: '...',
            pages: [],
            page: 1
        };
    },

    methods: {

        doFetchPeguins: async function () {
            let { data } = await axios.get('/api/fetch/peguins');
            console.log(data);
            this.getPeguins();
        },

        getPeguins: async function () {
            let { data } = await axios.get('/api/my/peguins');
            this.items = data.data;
        }
    },

    computed: {
    },
    watch: {
    },

    mounted() {
        this.getPeguins();
    },
};
</script>
