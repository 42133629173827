import { DefaultLoadingScreen, Engine, Scene, Vector3, Color3, PBRMaterial, Color4, PointLight, SceneLoader, GlowLayer, ArcRotateCamera, StandardMaterial, CubeTexture, Texture, MeshBuilder } from "@babylonjs/core";

import "@babylonjs/loaders/glTF";

DefaultLoadingScreen.prototype.displayLoadingUI = function () {
    if (this._loadingDiv) {
        // Do not add a loading screen if there is already one
        return;
    }
    this._loadingDiv = document.createElement("div");
    this._loadingDiv.id = "babylonjsLoadingDiv";
    this._loadingDiv.style.opacity = "0";
    this._loadingDiv.style.transition = "opacity 1.5s ease";
    this._loadingDiv.style.pointerEvents = "none";
    // Loading text
    this._loadingTextDiv = document.createElement("div");
    this._loadingTextDiv.style.position = "absolute";
    this._loadingTextDiv.style.left = "0";
    this._loadingTextDiv.style.top = "50%";
    this._loadingTextDiv.style.marginTop = "80px";
    this._loadingTextDiv.style.width = "100%";
    this._loadingTextDiv.style.height = "20px";
    this._loadingTextDiv.style.fontFamily = "Arial";
    this._loadingTextDiv.style.fontSize = "14px";
    this._loadingTextDiv.style.color = "white";
    this._loadingTextDiv.style.textAlign = "center";
    this._loadingTextDiv.innerHTML = "Loading";
    this._loadingDiv.appendChild(this._loadingTextDiv);
    //set the predefined text
    this._loadingTextDiv.innerHTML = this._loadingText;
    // Generating keyframes
    //var style = document.createElement('style');
    //style.type = 'text/css';
    //var keyFrames = "@-webkit-keyframes spin1 {                    0% { -webkit-transform: rotate(0deg);}\n                    100% { -webkit-transform: rotate(360deg);}\n                }                @keyframes spin1 {                    0% { transform: rotate(0deg);}\n                    100% { transform: rotate(360deg);}\n                }";
    //style.innerHTML = keyFrames;
    //document.getElementsByTagName('head')[0].appendChild(style);
    // Loading img
    var imgBack = new Image();
    imgBack.src = "/img/NovaFrontier.4222eda4.png";
    imgBack.style.position = "absolute";
    imgBack.style.left = "25%";
    imgBack.style.top = "25%";
    // imgBack.style.marginLeft = "-60px";
    // imgBack.style.marginTop = "-60px";
    //imgBack.style.animation = "spin1 2s infinite ease-in-out";
    //imgBack.style.webkitAnimation = "spin1 2s infinite ease-in-out";
    //imgBack.style.transformOrigin = "50% 50%";
    //imgBack.style.webkitTransformOrigin = "50% 50%";
    this._loadingDiv.appendChild(imgBack);
    this._resizeLoadingUI();
    window.addEventListener("resize", this._resizeLoadingUI);
    this._loadingDiv.style.backgroundColor = this._loadingDivBackgroundColor;
    document.body.appendChild(this._loadingDiv);
    this._loadingDiv.style.opacity = "0";
};

var startRenderLoop = function (engine) {
    engine.runRenderLoop(function () {
        if (sceneToRender && sceneToRender.activeCamera) {
            sceneToRender.render();
        }
    });
}
var engine = null;
var scene = null;
var sceneToRender = null;
var createDefaultEngine = function (canvas) {
    return new Engine(canvas, true, {
        preserveDrawingBuffer: true,
        stencil: true,
        disableWebGL2Support: false
    });
};
var filename = "";
var createSceneX = function (canvas) {
    var scene = new Scene(engine);
    var camera = new ArcRotateCamera("cam", 1.65, 1.56, 85, Vector3.Zero());
    scene.clearColor = new Color3(0, 0, 0);
    camera.wheelDeltaPercentage = 0.01;
    camera.attachControl(canvas, true);
    camera.upperBetaLimit = 1.75;
    camera.lowerRadiusLimit = 5;
    camera.upperRadiusLimit = 105;

    // Material
    var groundMat = new PBRMaterial("groundMat", scene);
    groundMat.albedoColor = new Color4(63 / 255, 52 / 255, 97 / 255, 1);
    groundMat.metallic = 0;
    groundMat.roughness = 0.4;
    groundMat.environmentIntensity = 0;
    // Ground
    /*var ground = Mesh.CreatePlane("ground", 500.0, scene);
    ground.position = new Vector3(0, -10, 0);
    ground.rotation = new Vector3(Math.PI / 2, 0, 0);
    ground.material = groundMat; */
    // Lights
    var light = new PointLight("pointLight", new Vector3(-17, 68, -5), scene);
    light.intensity = 13000;



    // Skybox
    var skybox = MeshBuilder.CreateBox("skyBox", { size: 1000.0 }, scene);
    var skyboxMaterial = new StandardMaterial("skyBox", scene);
    skyboxMaterial.backFaceCulling = false;
    skyboxMaterial.reflectionTexture = new CubeTexture("https://pub-76ac83c393534993a2a33844820b2f10.r2.dev/textures%2FTropicalSunnyDay", scene);
    skyboxMaterial.reflectionTexture.coordinatesMode = Texture.SKYBOX_MODE;
    skyboxMaterial.diffuseColor = new Color3(0, 0, 0);
    skyboxMaterial.specularColor = new Color3(0, 0, 0);
    skybox.material = skyboxMaterial;

    //light.includedOnlyMeshes.push(ground);
    // Environment
    //var hdrTexture = CubeTexture.CreateFromPrefilteredData("https://playground.babylonjs.com/textures/Studio_Softbox_2Umbrellas_cube_specular.dds", scene);

    //hdrTexture.name = "envTex";
    //hdrTexture.gammaSpace = false;
    //hdrTexture.setReflectionTextureMatrix(Matrix.RotationY(2.4));
    //scene.environmentTexture = hdrTexture;
    //var ccMask = new Texture("./textures/AI2_NovaFrontier_baseColor.jpg", scene, undefined, false);

    //var mat = new StandardMaterial("mat", scene);
    //var texture = new Texture("https://haybook.hn.ss.bfcplatform.vn/test/textures/AI2_NovaFrontier_baseColor.jpg", scene);
    //mat.diffuseTexture = texture;

    engine.displayLoadingUI();
    //filename = 'y2k_terminator';
    Promise.all([
        //SceneLoader.ImportMesh("", "https://pub-76ac83c393534993a2a33844820b2f10.r2.dev/", filename + ".glb", scene)
        //SceneLoader.AppendAsync("https://haybook.hn.ss.bfcplatform.vn/test/samplee/untitled.glb",sce)
        SceneLoader.AppendAsync("https://pub-76ac83c393534993a2a33844820b2f10.r2.dev/", filename + '.gltf', scene)
    ]).then(function () {

        var gl = new GlowLayer("glow", scene, {
            mainTextureFixedSize: 1024,
            blurKernelSize: 12
        });
        gl.intensity = 0.13;

        // Enable clear coat on material
        /* var mainMat = scene.getMaterialByName("AI2_NovaFrontier_baseColor");
        mainMat.clearCoat.isEnabled = true;
        mainMat.clearCoat.texture = ccMask;*/
        // Set up new rendering pipeline
        /* var pipeline = new DefaultRenderingPipeline("default", true, scene);
        // Tone mapping
        pipeline.toneMappingEnabled = true;
        pipeline.toneMappingType = ImageProcessingConfiguration.TONEMAPPING_ACES;
        pipeline.exposure = 1;
        pipeline.fxaaEnabled = true;
        // Glow Layer
         */
    });

    return scene;
};

async function createScene(canvas, name) {
    filename = name;
    var asyncEngineCreation = async function (canvas) {
        try {
            return createDefaultEngine(canvas);
        } catch (e) {
            console.log("the available createEngine function failed. Creating the default engine instead");
            return createDefaultEngine(canvas);
        }
    }

    //var canvas = document.getElementById("renderCanvas");
    canvas.addEventListener("wheel", evt => evt.preventDefault());
    engine = await asyncEngineCreation(canvas);
    startRenderLoop(engine, canvas);
    scene = createSceneX();
    sceneToRender = scene;
}

/*
const createScene = (canvas) => {
    const engine = new Engine(canvas);
    const scene = new Scene(engine);

    const camera = new FreeCamera("camera1", new Vector3(0, 5, -10), scene);
    camera.setTarget(Vector3.Zero());
    camera.attachControl(canvas, true);

    new HemisphericLight("light", Vector3.Up(), scene);

    const box = MeshBuilder.CreateBox("box", { size: 2 }, scene);
    const material = new StandardMaterial("box-material", scene);
    material.diffuseColor = Color3.Blue();
    box.material = material;

    engine.runRenderLoop(() => {
        scene.render();
    });
};
*/
export { createScene };