// import { v4 as uuidv4 } from 'uuid';
import { Buffer } from 'buffer/';
import axios from 'axios';
window.Buffer = Buffer; // needed to use `signSmartContractData` in browser
import Web3 from "web3";
//let contractAddress = process.env.VUE_APP_CONTRACT_ADDRESS;
let targetNetworkId = process.env.VUE_APP_NETWORK_ID;
const state = {
    loginRequestStatus: {
        error: false,
        msg: ""
    }
}
const actions = {
    // Action assign authenticate user
    async metamask({ commit }) {

        if (typeof window.ethereum === "undefined") {
            return commit("Web3LoginResult", {
                error: true,
                msg: "Please install Metamask and connect your wallet"
            })
        }

        // Request connect eth network
        try {

            try {
                await window.ethereum.request({
                    method: "wallet_switchEthereumChain",
                    params: [{ chainId: targetNetworkId }],
                });
            } catch (ex) {
                console.log(ex);
            }
            // Ask to connect
            await window.ethereum.send("eth_requestAccounts");
            const instance = new Web3(window.ethereum);
            // Get necessary info on your node
            var networkId = await instance.eth.net.getId();
            var coinbase = await instance.eth.getCoinbase();
            var balance = await instance.eth.getBalance(coinbase);
            this.networkId = networkId;
            this.address = coinbase;
            this.balance = balance;
            console.log("initWeb3 networkId: ", networkId);
            console.log("coinbase networkId: ", coinbase);
            console.log("balance networkId: ", balance);

            // Send request to web3
            let web3 = new Web3(window.ethereum);
            var accounts = await web3.eth.getAccounts();
            var account = accounts[0];


            const { data } = await axios.get('/api/web3_login/' + account + '/nonce')
            if (data.error) {
                console.error(data.msg);
                return commit("Web3LoginResult", {
                    error: true,
                    msg: data.msg
                })
            }
            const nonce = data.nonce

            let signedMessage = await web3.eth.personal.sign(web3.utils.fromUtf8("digital" + nonce), account)

            // Send signature to backend
            const responseSign = await axios.post('/api/web3_login/' + account + '/signature', { signature: signedMessage })
            if (responseSign.data && responseSign.data.error) {
                return commit("Web3LoginResult", {
                    error: true,
                    msg: responseSign.data.msg
                })
            }
            return commit("Web3LoginResult", {
                error: false,
                msg: ""
            })

        } catch (error) {
            // User denied account access
            console.error("User denied web3 access", error, targetNetworkId);
            return commit("Web3LoginResult", {
                error: true,
                msg: "Please install Metamask and connect your wallet"
            })
        }

    },
};

const mutations = {
    Web3LoginResult(state, data) {
        state.loginRequestStatus = data;
    }
};

export const web3_login = {
    namespaced: true,
    state,
    actions,
    mutations
};