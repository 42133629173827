const state = {
    popup: null,
}

const actions = {

    show({ commit }, payload) {
        commit("show", payload);
    },

};
const mutations = {

    show(state, payload) {
        state.popup = payload
    },
};

export const swal = {
    namespaced: true,
    state,
    actions,
    mutations
};