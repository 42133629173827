<style scoped>
.small-link {
    font-size: 12px;
}

.spaceship-attr {
    text-align: left;
    font-size: 13px;
}
</style>
<template>
    <div class="container-xl">
        <div style="height: 10rem;"></div>
        <div class="row filter-col">
            <div class="col-md-12 col-lg-3">

                <div>
                    <div class="filter-box mb-4">
                        <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-vercel" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M3 19h18l-9 -15z"></path>
                            </svg> Element</h4>
                        <div id="filters" class="mt-3">
                            <label class="form-check">
                                <input v-model="filter.element.ai" class="form-check-input" type="checkbox">
                                <span class="form-check-label text-class-1">AI</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.element.alien" class="form-check-input" type="checkbox">
                                <span class="form-check-label text-class-2">Alien</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.element.human" class="form-check-input" type="checkbox">
                                <span class="form-check-label text-class-0">Earth Human</span>
                            </label>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="filter-box mb-4">
                        <h4><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-loader-quarter" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 6l0 -3"></path>
                                <path d="M6 12l-3 0"></path>
                                <path d="M7.75 7.75l-2.15 -2.15"></path>
                            </svg> Rarity</h4>
                        <div id="filters" class="mt-3">
                            <label class="form-check">
                                <input v-model="filter.rarity.common" class="form-check-input" type="checkbox">
                                <span class="form-check-label">Common</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.rarity.rare" class="form-check-input" type="checkbox">
                                <span class="form-check-label">Rare</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.rarity.epic" class="form-check-input" type="checkbox">
                                <span class="form-check-label">Epic</span>
                            </label>
                            <label class="form-check">
                                <input v-model="filter.rarity.legend" class="form-check-input" type="checkbox">
                                <span class="form-check-label">Legendary</span>
                            </label>
                        </div>
                    </div>
                </div>






            </div>
            <div class="col-md-12 col-lg-9">
                <div id="rowresult">


                    <div class="page-header mx-2">
                        <div class="page-pretitle">Home > My Assets</div>

                        <div class="page-pretitle sort-by-div">
                            <h4>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M6 12l6 -9l6 9l-6 9z" />
                                    <path d="M6 12l6 -3l6 3l-6 2z" />
                                </svg>
                                Total DNC : {{ totalDNC }} DNC
                            </h4>
                        </div>
                        <div class="page-pretitle sort-by-div">
                            <h4>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-rocket" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                    <path d="M4 13a8 8 0 0 1 7 7a6 6 0 0 0 3 -5a9 9 0 0 0 6 -8a3 3 0 0 0 -3 -3a9 9 0 0 0 -8 6a6 6 0 0 0 -5 3" />
                                    <path d="M7 14a6 6 0 0 0 -3 6a6 6 0 0 0 6 -3" />
                                    <path d="M15 9m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0" />
                                </svg>
                                Spaceship : {{ total }} items
                            </h4>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="row" v-if="false">
                        <div class="col-3">
                            <b>Name</b>
                        </div>
                        <div class="col-3">
                            <b>Profile</b>
                        </div>
                        <div class="col-2">
                            <b>Rarity</b>
                        </div>
                        <div class="col-2">
                            <b>Market status</b>
                        </div>
                        <div class="col-2">
                            <b>Mint status</b>
                        </div>
                    </div>
                    <div class="row" v-for="item in items" v-bind:key="item._id">
                        <div class="col-12 col-md-3 text-center">
                            <a v-if="item">
                                <div class="image-ship">
                                    <img :src="getImage(item.image)" />
                                </div>
                            </a>
                        </div>
                        <div class="col-8 col-md-3">
                            <p> <span class="ship-class mx-2" :class="`ship-class-${item.element}`">
                                </span>
                                <b> {{ item.name }}</b>
                            </p>
                            <ul class="spaceship-attr">
                                <li>Afterburner : {{ item.afterburner }}</li>
                                <li>Defense : {{ item.defense }}</li>
                                <li>Gun Damage : {{ item.gun_damage }}</li>
                                <li>Healing : {{ item.healing }}</li>
                                <li>Health : {{ item.health }}</li>
                                <li>Rocket Damage : {{ item.rocket_damage }}</li>
                                <li>Rocket Fire Rate : {{ item.rocket_fire_rate }}</li>
                                <li>Rocket Reload Time : {{ item.rocket_re_time }}</li>
                            </ul>
                        </div>
                        <div class="col-4 col-md-2 text-center">
                            <div :class="'header-type r-' + item.rarity">
                                <template v-if="item.rarity == 0">Common</template>
                                <template v-if="item.rarity == 1">Rare</template>
                                <template v-if="item.rarity == 2">Epic</template>
                                <template v-if="item.rarity == 3">Legendary</template>
                            </div>
                        </div>
                        <div class="col-6 col-md-2 text-center">
                            <template v-if="item.listing_status">
                                <span class="text-listing">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-walmart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M12 8.04v-5.04"></path>
                                        <path d="M15.5 10l4.5 -2.5"></path>
                                        <path d="M15.5 14l4.5 2.5"></path>
                                        <path d="M12 15.96v5.04"></path>
                                        <path d="M8.5 14l-4.5 2.5"></path>
                                        <path d="M8.5 10l-4.5 -2.505"></path>
                                    </svg> On Market</span>
                            </template>
                            <template v-else>
                                Not listing <br />
                                <a @click="doListing(item)" class="btn">Listing now</a>
                            </template>
                        </div>
                        <div class="col-6 col-md-2 text-center">
                            <template v-if="item.mint_status">
                                <span class="text-listing">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M6 12l6 -9l6 9l-6 9z"></path>
                                        <path d="M6 12l6 -3l6 3l-6 2z"></path>
                                    </svg>
                                    NFT </span> <br />

                                <a target="_blank" class="small-link" :href="viewTransaction(item)">Transction</a>
                            </template>
                            <template v-else>
                                Soft NFT <br />
                                <a @click="doMintNFT(item)" class="btn">Mint now</a>
                            </template>
                        </div>
                    </div>
                </div>



                <table v-if="false" class="table table-vcenter table-mobile-md card-table text-white tbl-my-assets table-bordered table-scroll">
                    <tr>
                        <th>Name</th>
                        <th>Profile</th>
                        <th>Rarity</th>
                        <th>Market status</th>
                        <th>Mint Status</th>
                    </tr>
                    <tr v-for="item in items" v-bind:key="item._id">
                        <td>
                            <a v-if="item">
                                <div class="image-ship">
                                    <img :src="getImage(item.image)" />
                                </div>
                            </a>
                        </td>
                        <td>
                            <span class="ship-class mx-2" :class="`ship-class-${item.element}`">
                            </span>
                            <b>
                                {{ item.name }}</b>
                            <ul class="spaceship-attr">
                                <li>Afterburner : {{ item.afterburner }}</li>
                                <li>Defense : {{ item.defense }}</li>
                                <li>Gun Damage : {{ item.gun_damage }}</li>
                                <li>Healing : {{ item.healing }}</li>
                                <li>Health : {{ item.health }}</li>
                                <li>Rocket Damage : {{ item.rocket_damage }}</li>
                                <li>Rocket Fire Rate : {{ item.rocket_fire_rate }}</li>
                                <li>Rocket Reload Time : {{ item.rocket_re_time }}</li>
                            </ul>
                        </td>
                        <td>
                            <div :class="'header-type r-' + item.rarity">
                                <template v-if="item.rarity == 0">Common</template>
                                <template v-if="item.rarity == 1">Rare</template>
                                <template v-if="item.rarity == 2">Epic</template>
                                <template v-if="item.rarity == 3">Legendary</template>
                            </div>
                        </td>
                        <td>
                            <template v-if="item.listing_status">
                                <span class="text-listing">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-walmart" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M12 8.04v-5.04"></path>
                                        <path d="M15.5 10l4.5 -2.5"></path>
                                        <path d="M15.5 14l4.5 2.5"></path>
                                        <path d="M12 15.96v5.04"></path>
                                        <path d="M8.5 14l-4.5 2.5"></path>
                                        <path d="M8.5 10l-4.5 -2.505"></path>
                                    </svg> On Market</span>
                            </template>
                            <template v-else>
                                Not listing <br />
                                <a @click="doMintNFT(item)" class="btn">Listing now</a>
                            </template>
                        </td>
                        <td>
                            <template v-if="item.mint_status">
                                <span class="text-listing">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M6 12l6 -9l6 9l-6 9z"></path>
                                        <path d="M6 12l6 -3l6 3l-6 2z"></path>
                                    </svg>
                                    NFT </span> <br />

                                <a target="_blank" class="small-link" :href="viewTransaction(item)">Transction</a>
                            </template>
                            <template v-else>
                                Soft NFT <br />
                                <a @click="doMintNFT(item)" class="btn">Mint now</a>
                            </template>
                        </td>
                    </tr>
                </table>
                <div class="row">
                    <div class="col-12 text-center mt-4 pt-2">
                        <a @click="viewPage(i)" v-for="i in pages" :key="i" class="btn btn-page" :class="{ 'btn-primary': (i == page) }">{{ i }}</a>
                    </div><!--end col-->
                </div>

                <!-- Page body -->
                <div class="page-body mb-5">
                    <div class="container-xl">
                    </div>
                </div>
            </div>
        </div>



        <div id="exampleModal" tabindex="-1" class="modal modal-blur fade show" role="dialog" aria-modal="true" active="true" v-show="listingDialog.show">
            <div class="modal-dialog" id="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Listing to marketplace.
                        </h5>
                        <button @click="listingDialog.show = false" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body" style="overflow: hidden">
                        <div class="row">
                            <div class="col-sm-6 title-balance">
                                <b>Price</b> <br />
                                <small>Price listing to marketplace</small>
                            </div>
                            <div class="col-sm-6 value-balance">
                                <input class="form-control" v-model="listingDialog.price" />
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-sm-12 text-center">
                                <button class="btn approve" @click="doListingSpaceship">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-currency-ethereum" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M6 12l6 -9l6 9l-6 9z"></path>
                                        <path d="M6 12l6 -3l6 3l-6 2z"></path>
                                    </svg>
                                    Listing spaceship to marketplace
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.row.row-cards.list-ships {
    justify-content: left;
}

.row.row-cards.list-ships .ship-item {
    text-align: center;
    display: block;
}

.carousel__next--disabled,
.carousel__prev--disabled {
    opacity: 0;
}
</style>
<style>
.carousel__prev,
.carousel__icon {
    background-color: #078DDF !important
}
</style>
<script>
//import axios from "axios";
import axios from "axios";
import { mapState } from "vuex";

function objectToConcat(object1) {
    let item = [];
    for (const [key, value] of Object.entries(object1)) {
        if (value) {
            item.push(key);
        }
    }
    return item.join(',');
}


export default {
    name: "HomeMarketpalce",
    components: {
    },
    data() {
        return {
            totalDNC: 'Loading ...',
            listingDialog: {
                show: false,
                item: undefined,
                price: 0
            },
            filter: {
                element: {
                    ai: false,
                    alien: false,
                    earth: false
                },
                rarity: {
                    common: false,
                    rare: false,
                    epic: false,
                    legend: false
                },
                price: {
                    from: 0,
                    to: 0
                }
            },
            value: 1,
            items: [],
            total: '...',
            pages: [],
            page: 1
        };
    },

    methods: {
        viewTransaction(item) {
            return process.env.VUE_APP_BLCEXP + '/tx/' + item.txid
        },
        viewPage(p) {
            this.page = p;
            this.fetchItems();
            document.getElementById("rowresult").scrollIntoView();
        },
        priceRound(p) {
            return '$' + p.toFixed(3)
        },
        getImage(e) {
            return process.env.VUE_APP_CLOUDFRONT + '/' + e + '?tr=w-300,h-300';
        },

        fetchDNC: async function () {
            try {
                let { data } = await axios.get('/api/credit/total');
                this.totalDNC = data.amount;
            } catch (error) {
                console.log(error);
            }
        },

        fetchItems: async function () {
            let limit = 20;
            try {
                let filterQuery = {
                    element: objectToConcat(this.filter.element),
                    rarity: objectToConcat(this.filter.rarity),
                    price_from: this.filter.price.from,
                    price_to: this.filter.price.to,
                    page: this.page,
                    limit: limit
                }
                let { data } = await axios.get('/api/my/assets', { params: filterQuery });
                console.log(data);
                this.items = data.data;
                this.total = data.total;


                let numberPage = Math.round(data.total / limit);
                let ps = [];
                for (var i = (this.page - 8); i <= this.page + 8; i++) {
                    if (i > 0 && i <= numberPage) {
                        ps.push(i);
                    }
                }
                this.pages = ps;

            } catch (error) {
                console.log(error);
            }
        },
        filterBulder() {
            let filterQuery = {
                element: objectToConcat(this.filter.element),
                rarity: objectToConcat(this.filter.rarity),
                price_from: this.filter.price.from,
                price_to: this.filter.price.to,
            }
            this.$router.push({ query: filterQuery });
            this.fetchItems();
        },

        doListing(item) {
            let self = this;
            console.log(item);
            this.$swal
                .fire({
                    title: "By proceeding, you will listing spaceship to marketplace. When listing to marketplace, you can not use spaceship on your game.",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Proceed",
                    denyButtonText: `Cancel`,
                })
                .then((result) => {
                    console.log(result);


                    if (result.isConfirmed) {
                        self.listingDialog = {
                            show: true,
                            item: item,
                            price: 0
                        }




                        /*
                        this.$swal.fire({
                            title: "Please wait ...",
                            text: " ",
                            icon: "warning",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        });
                        item.state = 3;
                        this.$store.dispatch("minting/request", {
                            collectionID,
                            onReject: () => {
                                item.state = 0;
                            },
                            callback: function (txid) {
                                item.state = 4;
                                console.log("Minting completed", txid);
                                setTimeout(() => {
                                    self.fetchItems();
                                    //self.silentFetchMinting(item);
                                }, 1000);
                            },
                        });*/
                    }
                });
        },

        doMintNFT(item) {
            let self = this;
            let collectionID = item._id;
            this.$swal
                .fire({
                    title: "By proceeding, you will convert this Spaceship into an NFT on the ETH Network. Please note that this action cannot be undone.",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: "Proceed",
                    denyButtonText: `Cancel`,
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.$swal.fire({
                            title: "Please wait ...",
                            text: " ",
                            icon: "warning",
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                        });
                        item.state = 3;
                        this.$store.dispatch("minting/request", {
                            collectionID,
                            onReject: () => {
                                item.state = 0;
                            },
                            callback: function (txid) {
                                item.state = 4;
                                console.log("Minting completed", txid);
                                setTimeout(() => {
                                    self.fetchItems();
                                    //self.silentFetchMinting(item);
                                }, 1000);
                            },
                        });
                    }
                });


        },

        async doListingSpaceship() {
            this.$swal.fire({
                title: "Please wait ...",
                text: " ",
                icon: "warning",
                showConfirmButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            });
            let { data } = await axios.post('/api/marketplace/listing', {
                item: this.listingDialog.item._id,
                price: this.listingDialog.price
            });
            console.log(data);
        }
    },

    computed: {
        ...mapState("account", ["status", "creditAmount", "user"]),
        ...mapState("cart", [
            "totalItems",
            "itemsOnCart",
            "totalPrice",
            "itemsPrice",
            "checkoutStatus",
            "landType",
            "landCollection",
            "landPlotBuyResult",
            "addCartException",
            "showDialogPaymentStripe",
        ]),
        ...mapState("map", ["styleMap"]),
    },
    watch: {
        landPlotBuyResult: function ({ isError }) {
            this.clearSelectionLand();
            if (!isError) {
                this.fetchLands();
            }
        },

        addCartException: function ({ error, title, msg }) {
            this.$swal(title, msg, error ? "error" : false);
        },

        filter: {
            handler(newValue) {
                console.log(newValue);
                this.filterBulder();
            },
            deep: true
        }
    },

    // `mounted` is a lifecycle hook which we will explain later
    mounted() {
        this.fetchItems();
        // GET DNC
        // totalDNC
        this.fetchDNC();
    },
};
</script>
